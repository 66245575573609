/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.title {
    font-size: large
}


body .p-inputtext {
    font-size: 14px !important;
  }